@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

$family-sans-serif: "Fira Code", sans-serif;
$info: #d4cad1;
$link: #5284aa;
$primary: #5284aa;
$danger: #b02e2e;
$dark: #744e5d;
$body-background-color: #485966;
$danger: hsl(360, 100%, 40%);
$warning: #d4ab38;
$footer-padding: 1.2rem 1.2rem 1.2rem;
$footer-background-color: $body-background-color;

@import "../node_modules/bulma-switch-control/bulma-switch-control";
@import "../node_modules/bulma/bulma.sass";
@import "~@creativebulma/bulma-divider";

html,
body {
  min-height: 100vh;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}

.vl {
  border-left: 1px solid $info;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.ql-editor {
  min-height: 150px;
}
